<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
      >
        <el-form-item>
          <el-input
            v-model="formData.groupName"
            clearable
            placeholder="账号组名称/ID"
            type="text"
            @keyup.native.enter="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.platform"
            clearable
            placeholder="游戏平台"
          >
            <el-option
              v-for="(item, index) in gamePlatform"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.appId"
            clearable
            placeholder="关联应用"
          >
            <el-option
              v-for="(item, index) in appIdList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="formData.gameName"
            clearable
            placeholder="关联游戏，搜索匹配"
            type="text"
            @keyup.native.enter="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            筛选
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleAddAccountGroup"
          >
            新建
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          type="text"
          @click="handleGoToViewLog"
        >
          查看账号使用日志
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="accountGroupInfo.loading"
      :data="accountGroupInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="groupId"
        label="账号组ID"
        min-width="80px"
      />
      <el-table-column
        prop="idc_id"
        label="关联国家"
        width="120px"
      >
        <template #default="{row}">
          {{ row.idc_id | formatNation(engineRoomMap) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="gameName"
        label="关联游戏"
        min-width="100px"
      />
      <el-table-column
        prop="appId"
        label="关联应用"
        width="100px"
      >
        <template v-slot="{row}">
          {{ (appIdMap[row.appId] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="appSinglePackageName"
        label="关联包名"
        width="100px"
      >
        <template v-slot="{row}">
          {{ row.appSinglePackageName || '-' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="groupName"
        label="账号组名称"
        min-width="100px"
      />
      <el-table-column
        prop="description"
        min-width="100px"
        label="账号组描述"
      />
      <el-table-column
        prop="platform"
        min-width="100px"
        label="游戏平台"
      >
        <template v-slot="{row}">
          {{ (gamePlatform[row.platform] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="accountNumber"
        min-width="100px"
        label="账号数量"
      >
        <template v-slot="{row}">
          <el-button
            type="text"
            @click="handleGoToManageAccountGroup(row)"
          >
            {{ row.accountNumber }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="validNumber"
        min-width="100px"
        label="有效账号"
      />
      <el-table-column
        prop="usingNumber"
        min-width="100px"
        label="使用中"
      />
      <el-table-column
        prop="createTime"
        width="150px"
        label="创建时间"
      />
      <el-table-column
        fixed="right"
        width="170px"
        label="操作"
      >
        <template v-slot="{row}">
          <BTextButton
            title="管理账号"
            icon="fa fa-wrench"
            @click="handleGoToManageAccountGroup(row)"
          />
          <BTextButton
            title="编辑账号"
            icon="fa fa-pencil"
            @click="handleEditAccountGroup(row)"
          />
          <BTextButton
            :loading="row.loading"
            :title="row.enable === 1 ? '启用' : '停用'"
            :icon="`fa ${row.enable === 1 ? 'fa-check-circle' : 'fa-ban' }`"
            @click="handleToggleAccountGroup(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="accountGroupInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.size"
      @pagination="queryAccountGroupList(formData)"
    />
    <!--    新增或者编辑-->
    <el-dialog
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="handleSubFormClose"
    >
      <el-form
        ref="subFormData"
        :model="subFormData"
        :rules="subFormDataRules"
        label-width="100px"
        class="subForm"
      >
        <!--        <el-form-item-->
        <!--          label="游戏ID"-->
        <!--          prop="gameId"-->
        <!--        >-->
        <!--          <el-input-->
        <!--            v-model="subFormData.gameId"-->
        <!--            type="text"-->
        <!--            placeholder="单行输入"-->
        <!--          />-->
        <!--        </el-form-item>-->
        <el-form-item
          label="APP ID"
          prop="appId"
        >
          <el-radio-group v-model="subFormData.appId">
            <el-radio
              v-for="item in appIdList"
              :key="item.value"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="游戏平台"
          prop="platform"
        >
          <el-radio-group v-model="subFormData.platform">
            <el-radio
              v-for="item in gamePlatform"
              :key="item.value"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="关联包"
          prop="appSinglePackageName"
        >
          <el-input
            v-model="subFormData.appSinglePackageName"
            placeholder="关联包名"
          />
        </el-form-item>

        <el-form-item
          label="机房"
          prop="idc_id"
        >
          <el-select
            v-model="subFormData.idc_id"
            clearable
            placeholder="选择机房"
            style="width: 420px;"
          >
            <el-option
              v-for="item in gameRoomList"
              :key="item.roomId"
              :label="item.roomName"
              :value="item.roomId"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="关联游戏"
          prop="gameInfo"
        >
          <el-select
            v-model="subFormData.gameInfo"
            value-key="gameId"
            style="width: 420px;"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="remoteLoading"
          >
            <el-option
              v-for="item in options"
              :key="item.gameId"
              :label="item.gameName"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="账号组名称"
          prop="groupName"
        >
          <el-input
            v-model="subFormData.groupName"
            type="text"
            placeholder="单行输入"
          />
        </el-form-item>
        <el-form-item
          label="描述"
          prop="description"
        >
          <el-input
            v-model="subFormData.description"
            type="textarea"
            :autosize="{ minRows: 4 }"
            placeholder="多行输入"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            v-model="subFormData.enable"
            :active-value="1"
            :inactive-value="2"
            active-text="启用"
            inactive-text="停用"
          />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <div class="ctrl-area">
          <el-button
            :loading="updateLoading"
            type="primary"
            @click="handleUpdateAccountGroup"
          >
            保存
          </el-button>
          <el-button @click="handleDialogClose">
            取消
          </el-button>
        </div>
      </template>
    </el-dialog>
  </Layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { updateAccountGroup } from '@/api/rentAccount'
import { searchGameList, getEngineRoomInfo } from '@/api/gameList'
import { cloneDeep } from 'lodash'
import { skeletonFinish } from '@/utils'
// import { toggleGroupStatus } from '@/api/APP'
import { gamePlatform, appIdList, appIdMap } from '@/utils/selectOptions'

const initSubFormData = () => ({
  appId: 12, // ID为0是新建，大于0是更新
  groupId: 0, // ID为0是新建，大于0是更新
  gameId: '',
  groupName: '',
  description: '',
  enable: 1,
  platform: 1,
  appSinglePackageName: ''
})

const matchREG = /(?<=-)(.*)(?=-)|(?<=-)(.*)(?=(\())/g
export default {
  name: 'RentAccount',
  filters: {
    formatNation (roomId = 83, roomMap) {
      return `${roomId ?? ''} ${roomMap[roomId]?.roomName.match(matchREG)?.[0] ?? ''}`
    }
  },
  data () {
    return {
      gamePlatform,
      appIdList,
      appIdMap,
      formData: {
        page: 1,
        size: 20
      },
      subFormData: initSubFormData(),
      subFormDataRules: {
        appId: [{ required: true }],
        platform: [{ required: true }],
        gameInfo: [{ required: true }],
        groupName: [{ required: true }],
        description: [{ required: true }]
      },
      updateLoading: false,
      remoteLoading: false,
      dialogVisible: false,
      options: [],
      gameRoomList: [],
      engineRoomMap: {}
    }
  },
  computed: {
    ...mapState('rentAccount', ['accountGroupInfo'])
  },
  created () {
    this.queryAccountGroupList(this.formData)
    this.getEngineRoomInfo()
    this.skeletonFinish('accountGroupInfo.loading')
  },
  methods: {
    ...mapActions('rentAccount', ['queryAccountGroupList']),
    skeletonFinish,
    getEngineRoomInfo () {
      getEngineRoomInfo({})
        .then(res => {
          if (res.code === 200) {
            this.gameRoomList = res.data.list.map(item => ({ ...item, roomId: Number(item.roomId) }))
            this.engineRoomMap = res.data.list.reduce((result, item) => {
              return {
                [item.roomId]: item,
                ...result
              }
            }, {})
          }
        })
    },
    handleSearch () {
      this.formData.page = 1
      this.queryAccountGroupList(this.formData)
    },
    handleGoToViewLog () {
      this.$router.push({ name: 'RentAccountLog' })
    },
    handleGoToManageAccountGroup (row) {
      this.$router.push({
        name: 'RentAccountManage',
        query: {
          groupId: row.groupId
        }
      })
    },
    handleUpdateAccountGroup () {
      this.$refs.subFormData.validate().then(valid => {
        if (valid) {
          this.updateLoading = true
          const subFormData = cloneDeep(this.subFormData)
          subFormData.gameId = subFormData.gameInfo.gameId
          subFormData.gameName = subFormData.gameInfo.gameName
          delete subFormData.gameInfo
          updateAccountGroup(subFormData)
            .then(res => {
              if (res.code === 200) {
                this.$message.success('success!')
                this.dialogVisible = false
                this.queryAccountGroupList(this.formData)
              }
            }).finally(() => {
              this.updateLoading = false
            })
        }
      })
    },
    handleEditAccountGroup (row) {
      this.subFormData = JSON.parse(JSON.stringify(row))
      const gameInfo = {
        gameId: row.gameIdStr,
        gameName: row.gameName
      }
      this.$set(this.subFormData, 'gameInfo', gameInfo)
      this.options = [gameInfo]
      this.dialogVisible = true
    },
    handleAddAccountGroup () {
      this.subFormData = initSubFormData()
      this.dialogVisible = true
    },

    handleToggleAccountGroup (row) {
      const { usingNumber, enable } = row
      if (usingNumber > 0) {
        this.$confirm('当前账号组中有账号正在使用中，无法禁用，请稍后重试', '提示', {
          type: 'warning ',
          confirmButtonText: '好的',
          showCancelButton: false
        })
        return false
      } else {
        if (enable === 1) {
          this.$confirm('此操作将禁用该账号组， 是否继续?', '提示', { type: 'warning ' })
            .then(() => {
              this.toggleAccountGroupStatus(row, 2)
            })
        } else {
          this.$confirm('此操作将启用该账号组， 是否继续?', '提示', { type: 'warning ' })
            .then(() => {
              this.toggleAccountGroupStatus(row, 1)
            })
        }
      }
    },
    toggleAccountGroupStatus (row, enable) {
      /* eslint-disable */
      const { groupId, gameIdStr: gameId, gameName, groupName, description, appId, idc_id } = row
      this.$set(row, 'loading', true)
      updateAccountGroup({ groupId, gameId, groupName, gameName, description, enable, appId, idc_id })
        .then(res => {
          if (res.code === 200) {
            this.$set(row, 'enable', enable)
          }
        }).finally(() => {
          this.$set(row, 'loading', false)
        })
    },
    // 取消弹窗 二次确认
    dialogCloseConfirm () {
      return this.$confirm('确认取消吗', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
    },
    handleDialogClose () {
      this.dialogCloseConfirm()
        .then(() => {
          this.dialogVisible = false
        })
    },
    handleSubFormClose (done) {
      this.dialogCloseConfirm()
        .then(() => {
          done()
        })
    },

    remoteMethod (query) {
      if (!query) return
      this.remoteLoading = true
      searchGameList({ keyWord: query, pageNum: 1, pageSize: 30 })
        .then(res => {
          if (res.code === 200) {
            this.options = res.data.list.map(item => {
              return {
                gameId: item.gameIdStr,
                gameName: item.gameName
              }
            })
          }
        })
        .finally(() => {
          this.remoteLoading = false
        })
    }
  }
}
</script>

<style scoped lang="less">
  .subForm{
    .el-form-item{
      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
  .ctrl-area{
    text-align: center;
    .el-button{
      padding: 10px 40px;
    }
  }
</style>
